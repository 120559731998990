import ImageGallery from "../components/gallery/gallery";

const Media = () => {
  return (
    <section className="main diff">
      <h1 className="mainHeading">Foto y Video</h1>
      <ImageGallery />
    </section>
  )
}

export default Media;