import { SocialIcon } from 'react-social-icons';
import './footer.css';

const Footer = () => {
  return (      
    <footer className="footer">
      <div className="socials">
        <h1>Seguidnos en:</h1>
        <ul>
          <li>
            <SocialIcon style={{ height: 25, width: 25 }} url='https://www.facebook.com/gruporumbakana/' />
            <a href="https://www.facebook.com/gruporumbakana/" rel='noreferrer' target={'_blank'}>Facebook</a>
          </li>
          <li>
            <SocialIcon style={{ height: 25, width: 25 }} url='https://www.instagram.com/rumbakana/' />
            <a href="https://www.instagram.com/rumbakana/" rel='noreferrer' target={'_blank'}>Instagram</a>
          </li>
          <li>
            <SocialIcon style={{ height: 25, width: 25 }} url='https://twitter.com/Gruporumbakana' />
            <a href="https://twitter.com/Gruporumbakana" rel='noreferrer' target={'_blank'}>Twitter</a>
          </li>
          <li>
            <SocialIcon style={{ height: 25, width: 25 }} url='https://www.youtube.com/@rumbakana2804' />
            <a href="https://www.youtube.com/channel/UC7SZwPyOqzgEMmAIqPARNmA" rel='noreferrer' target={'_blank'}>Youtube</a>
          </li>
          <li>
            <SocialIcon style={{ height: 25, width: 25 }} url='https://open.spotify.com/artist/4XdtcO5PlsJx1zpFUDBOrZ' />
            <a href="https://open.spotify.com/artist/4XdtcO5PlsJx1zpFUDBOrZ" rel='noreferrer' target={'_blank'}>Spotify</a>
          </li>
        </ul>
      </div>
      <div className="contacts">
        <h1>Contactos:</h1>
        <ul>  
          <li>
            <a href="mailto:rumbakana@outlook.com">Mail</a>
          </li>
          <li>
            <a href="tel:+34637403469">Móvil</a>
          </li>
        </ul>
      </div>
    </footer>
  )    
}

export default Footer;