import React, { useState, useRef, useEffect } from 'react';
import { Outlet, NavLink } from "react-router-dom";
import Footer from './components/footer/footer';
import Header from './components/header/header';

const useOnClickOutside = (ref, handler) => {
  useEffect(() => {
    const listener = event => {
      if (!ref.current || ref.current.contains(event.target)) {
        return;
      }
      handler(event);
    };
    document.addEventListener('mousedown', listener);

    return () => {
      document.removeEventListener('mousedown', listener);
    };
  },[ref, handler]);
};

const Menu = ({open, onClick}) => {
  return (<div className={`menu ${open ? 'open' : ''}`} >
    <ul>
      <li>
        <NavLink onClick={onClick} to="/">Inicio</NavLink>
      </li>
      <li>
        <NavLink onClick={onClick} to="/conciertos">Conciertos</NavLink>
      </li>
      <li>
        <NavLink onClick={onClick} to="/discografia">Discografia</NavLink>
      </li>
      <li>
        <NavLink onClick={onClick} to="/biografia">Biografia</NavLink>
      </li>
      <li>
        <NavLink onClick={onClick} to="/media">Galeria</NavLink>
      </li>
    </ul>
  </div>);
}

const App = () => {
  const [open, setOpen] = useState(false);
  const node = useRef(null);
  useOnClickOutside(node, () => setOpen(false));

return (<>
    <Header onClick={() => setOpen(!open)} open={open} />
    <Menu onClick={() => setOpen(!open)} open={open} />
    <main>
      <Outlet />
    </main>
    <Footer />
  </>);
}

export default App;
