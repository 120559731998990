import React, { useState, } from 'react';
import './gallery.css';
 
import i1 from '../../media/i1.jpg';
import i2 from '../../media/i2.jpg';
import i3 from '../../media/i3.jpg';
import i4 from '../../media/i4.jpg';
import i5 from '../../media/i5.jpg';
import i6 from '../../media/i6.jpg';
import i7 from '../../media/i7.jpg';
import i8 from '../../media/i8.jpg';
import i9 from '../../media/i9.jpg';
import i10 from '../../media/i10.jpg';
import i11 from '../../media/i11.jpg';
import i12 from '../../media/i12.jpg';
import i13 from '../../media/i13.jpg';
import i14 from '../../media/i14.jpg';
import i15 from '../../media/i15.jpg';
import i16 from '../../media/i16.jpg';
import i17 from '../../media/i17.jpg';
import i19 from '../../media/i20.webm';

const images = [i1, i2, i3, i4, i5, i6, i7, i8, i9, i10, i11, i12, i13, i14, i15, i16, i17];
const videos = [i19];

const ImageGallery = () => {
  const [imageToShow, setImageToShow] = useState("");
  const [lightboxDisplay, setLightBoxDisplay] = useState(false);
  
  const imageCards = images.map((image, index) => (
    <img alt="" className="image-card" key={`image-${index}`} onClick={() => showImage(image)} src={image} />
  ));

  const videoCards = videos.map((image, index) => (
    <video className="image-card video" key={`video-${index}`} src={image} controls />
  ));

  const showImage = (image) => {
    setImageToShow(image);
    setLightBoxDisplay(true);
  };

  const hideLightBox = () => {
    setLightBoxDisplay(false);
  };

  const showNext = (e) => {
    e.stopPropagation();  
    let currentIndex = images.indexOf(imageToShow);
    if (currentIndex >= images.length - 1) {
      setLightBoxDisplay(false);
    } else {
      let nextImage = images[currentIndex + 1];
      setImageToShow(nextImage);
    }
  };

  const showPrev = (e) => {
    e.stopPropagation();
    let currentIndex = images.indexOf(imageToShow);
    if (currentIndex <= 0) {
      setLightBoxDisplay(false);
    } else {
      let nextImage = images[currentIndex - 1];
      setImageToShow(nextImage);
    }
  };
  
  return (
    <>
      <div className='glr'>
        {videoCards}
        {imageCards}
      </div>
      {
        lightboxDisplay ? 
        <div id="lightbox" onClick={hideLightBox}>
          <button onClick={showPrev}> {"<"} </button>
          <img alt="" id="lightbox-img" src={imageToShow}></img>
          <button onClick={showNext}> {">"} </button>
        </div>
       : null
      }
    </>
  );
}

export default ImageGallery;