import grupo from '../media/grupo.jpg'
const Biografia = () => {
  return <section className="main">
    <h1>Biografía</h1>
    <article className='bio'>
      <img src={grupo} alt="" />
      <p>Rumbakana es un grupo que se forma en las calles de Barcelona en el 2015 integrado por artistas de Colombia, Argentina, Uruguay y Cataluña, aclamados en muchos locales de Barcelona, fiestas de barrio y festivales por su potente directo lleno de frescura, improvisación loca y un aire canalla que no deja indiferente.</p>
      <p>Su paso por Francia, Italia, Madrid y toda Cataluña han hecho vibrar de alegría a su gente con sus versiones divertidas y los temas propios de su primer álbum SÁLVESE QUIEN PUEDA, con colaboraciones de diferentes artistas entre los que se encuentran Manu Chao y muchos otros cracks que harán bailar al son de la rumba callejera a todo el mundo.</p>
      <p>El Tete, cantante y compositor del grupo influenciado por Los Delincuentes, Muchachito, Bambino, Gipsy Kings entre otros da un explosivo show que sin duda hace olvidar a cualquiera sus penas.</p>
    </article>
  </section>
}

export default Biografia;