import { NavLink, Link } from "react-router-dom";
import logo from '../../media/logo.jpg';
import './header.css';

const Burger = ({onClick, open}) => {
  return <div onClick={onClick} className={`burger`}>
    <div className={`${open ? 'open' : ''}`}/>
    <div className={`${open ? 'open' : ''}`}/>
    <div className={`${open ? 'open' : ''}`}/>
  </div>
}

const Header = ({open, onClick, bob}) => {

  return (<>
    <header className="header">
      <nav>
        <Burger onClick={onClick} open={open}/>
        <Link to="/">
          <img src={logo} className="logo" alt="Rumbakana logo" />
        </Link>
        <ul ref={bob}>
          <li>
            <NavLink onClick={onClick} to="/">Inicio</NavLink>
          </li>
          <li>
            <NavLink onClick={onClick} to="/conciertos">Conciertos</NavLink>
          </li>
          <li>
            <NavLink onClick={onClick} to="/discografia">Discografia</NavLink>
          </li>
          <li>
            <NavLink onClick={onClick} to="/biografia">Biografia</NavLink>
          </li>
          <li>
            <NavLink onClick={onClick} to="/media">Galeria</NavLink>
          </li>
        </ul>
      </nav> 
    </header>
  </>    
  );    
}

export default Header;