import main from '../media/ft.jpg'
import main2 from '../media/ft2.jpg'

const Discografia = () => {
  return <section className='main'>
    <h1>Discografia</h1>

    <div className="releases">
      <div className="release">
        <img src={main} alt="" />
        <h2>Sálvese quien pueda</h2>
        <p>
          Primer álbum de la banda mestiza de Barcelona cargado de colaboraciones de artistas callejeros y otros consolidados como Manu Chao, Yuri Hernández, etc… que no dejarán indiferente a nadie.
          La producción de Hugo Motta, Matumati, MachuQa Records, y finalmente la masterización en la Atlántida Records dieron sus frutos en este álbum sencillo, alegre y canalla, con el que la banda de Rumba barcelonesa se presenta.
        </p>
      </div>

      <div className="release">
        <img src={main2} alt="" />
        <h2>Dejáme</h2>
        <p>Déjame es un tema cargado de mensaje positivo q compuso el Teté cantante de la banda y que enseguida gusto a Manu Chao que se presto encantado a poner la guinda al tema con su voz particular.</p>
      </div>
    </div>
  </section>
}

export default Discografia;