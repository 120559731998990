import img from '../media/main.jpg'
const Index = () => {
    return (
        <section className='main'>
            <img alt="" src={img} className="bg" />
            <h1 className='mainHeading'>Bienvenidos a la pagina de Rumbakana!</h1>
        </section>
    )
};

export default Index;