const Conciertos = () => {
  return <>
    <h1>Proximos Conciertos:</h1>
    <section className="main mar" >

      {/* <div className="concert">
        <div className="t1">
          <div className="bgimg i"></div>
          <div className="w1">
            <div className="info">
              <div className="artist">
                  <h1 className="m">Rumbakana</h1>
                  <p className="s">& Compañia</p>
              </div>
              <p>- presentan -</p>
              <h1 className="title">Salvese quien pueda</h1>
              <div className="dp">
                  <h1 className="d">05 Marzo 2023 H: 20.00</h1>
                  <h1 className="p">@ Marula - BCN</h1>
              </div>
            </div>
          </div>
          <div className="rip"></div>
          <div className="w2">
            <div className="otherinfo">
              <h1>Entradas en:</h1>
              <h2>Mariatchi</h2>
            </div>
          </div>
        </div>
      </div> */}

      <div className="dual">
        <div className="concert">
            <div className="bgimg b1"></div>
            <h1 className="m">Rumbakana</h1>
            <h1 className="title">11/03 2023 H: 21.00</h1>
            <h1 className="title">@Jazzsi - BCN</h1>
        </div>
 
        <div className="concert">
          <div className="bgimg b5"></div>
          <h1 className="m">Matcha y el Bloque Depresivo & Rumbakana</h1>
          <h1 className="title">23/03 2023 H: 20.00</h1>
          <h1 className="title">@Sala Paral.lel 62 - BCN</h1>
        </div>
      </div>

      <div className="dual">

        <div className="concert">
          <div className="bgimg b6"></div>
          <h1 className="title">Rumbakana</h1>
          <h1 className="title">25/03 2023  H: 18.00</h1>
          <h1 className="title">@Il morto che parla - MAD</h1>
        </div>

        <div className="concert">
          <div className="bgimg b7"></div>
          <h1 className="title">Rumbakana</h1>
          <h1 className="title">26/03 2023  H: 19.30</h1>
          <h1 className="title">@Fun House - MAD</h1>
        </div>

      </div>

      <div className="dual">
        <div className="concert">
          <div className="bgimg b4"></div>
          <h1 className="title">Matcha y el Bloque Depresivo & Rumbakana</h1>
          <h1 className="title">28/03 2023  H: 20.00</h1>
          <h1 className="title">@Cafe Berlín - MAD</h1>
        </div>

        <div className="concert">
          <div className="bgimg b3"></div>
          <h1 className="m">Rumbakana</h1>
          <h1 className="title">01/04 2023 H: 21.00</h1>
          <h1 className="title">@Almo2Bar - BCN</h1>
        </div>
      </div>

    </section>
  </>
}

export default Conciertos;            