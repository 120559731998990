import React from 'react';
import ReactDOM from 'react-dom/client';
import {
  createBrowserRouter,
  RouterProvider,
} from "react-router-dom";
import './index.css';
import App from './App';
// import reportWebVitals from './reportWebVitals';
import ErrorPage from './pages/error';
import Biografia from './pages/biografia';
import Conciertos from './pages/conciertos';
import Media from './pages/galeria';
import Discografia from './pages/discografia';
import Index from './pages/inicio';

const router = createBrowserRouter([
  {
    path: "/",
    element: <App />,
    errorElement: <ErrorPage />,
    children: [
      {
        path: "/",
        element: <Index />,        
      },
      {
        path: "/conciertos",
        element: <Conciertos />,
      },
      {
        path: "/discografia",
        element: <Discografia />,
      },
      {
        path: "/biografia",
        element: <Biografia />,
      },
      {
        path: "/media",
        element: <Media />,
      },
    ],
  },
]);

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <RouterProvider router={router} />
  </React.StrictMode>
);

// reportWebVitals();
